import React, { useEffect, useRef } from "react";
import Icon from "./icon";
import "./checkbox.global.scss";
import Button from "./button";

/* 
  NOTE: 
  
  This Checkbox component renders a real input element of type "checkbox" for accessibility, 
  but hides the real one and renders a custom styled div element to the UI for use. 

  To use this checkbox, your calling component will need to manage the checked state
  and provide markup to enclose the checkbox with a label. See the example below.
  
  If you encounter a vertical shifting (1-4px) of the label or content below the checkbox,
  try setting a height to you label to match the height of the checkbox as in the example code below.
*/

/* 
  // EXAMPLE CODE
  const MyCallingComponent = () => {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = function() {
      setIsChecked(!isChecked);
    };
    const cbData = {
      checkedState: isChecked, // required - boolean
      onChange: handleCheckboxChange, // required - function
      id: "my-checkbox-01", // optional - string
      containerClass: "", // optional - string
      passthroughProps: {} // optional - object
    };
    return (
      <div>
        <label style={{ min-height: "1.6rem", height: "100%" }}>
          <Checkbox {...cbData} />
          <span className="ml-2">Label Text</span>
        </label>
      </div>
    );
  };
*/
const Checkbox = (props) => {
  //console.log("Checkbox props: ", props);
  const checkedClass = props.checkedState ? "checked" : "";

  const inputRef = useRef(null);
  const btnCheckboxRef = useRef(null);

  const handleOnChange = () => {
    props.onChange(!props.checkedState);
  };

  const checkboxIconProps = {
    id: `${props.id}-button`,
    buttonRef: btnCheckboxRef,
    type: "button",
    class: `styled-checkbox ${checkedClass}`,
    text: "",
    onClick: handleOnChange,
    showIcon: true,
    icon: {
      name: "check",
      lib: "far",
      class: "icon-check"
    }
  };

  useEffect(() => {
    const handleFocus = () => {
      // console.log("Input focused");
      if (btnCheckboxRef.current) btnCheckboxRef.current.focus();
    };

    if (inputRef.current) {
      inputRef.current.addEventListener("focus", handleFocus);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  return (
    <div className="checkbox-container">
      <input
        id={props.id}
        ref={inputRef}
        type="checkbox"
        checked={props.checkedState}
        onChange={handleOnChange}
        {...props.passthroughProps}
      />
      <Button {...checkboxIconProps} />
      <label className={`checkbox-label text-sm ${props.fullWidth ? "w-100" : ""}`} htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;

const defaultChangeHandler = (event) => {
  event.preventDefault();
  console.error("Please implement a onChange handler for your Checkbox.");
};

Checkbox.defaultProps = {
  checkedState: false, // required - boolean
  onChange: defaultChangeHandler, // required - function
  id: "checkbox-01", // optional - string
  containerClass: "", // optional - string
  passthroughProps: {} // optional - object
};

